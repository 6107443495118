import ISkillTreeEntry from '@/models/skills/ISkillTreeEntry';
import { Actions, BUILD_LENGTH, IAwakeningMods, ISkillFullData, ISkillSimState, ISkillTree, ISp, Mutations } from '@/views/SkillSimStore';
import Vue, { VNode } from 'vue';
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapMutations } = createNamespacedHelpers('skillsim');

const alphabet = `0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJLKMNOPQRSTUVWXYZ`

export default Vue.extend({
    render(): VNode {
        return null!;
    },
    computed: {
        skillSimState(): ISkillSimState {
            return (this.$store.state as any).skillsim;
        },
        maxAvailableLevel(): number[] {
            return this.skillSimState.maxAvailableLevel;
        },
        skillBuild(): number[] {
            return this.skillSimState.skillBuild;
        },
        skills(): Map<number, ISkillFullData> {
            return this.skillSimState.skills;
        },
        skillTree(): ISkillTree {
            return this.skillSimState.skillTree;
        },
        maxSp(): ISp {
            return this.skillSimState.maxSp;
        },
    },
    watch: {
        skillBuild() {
            this.recomputeMaxAvailableLevels();
            this.updateUrl();
            this.updateAwakenings();
        },
    },
    mounted() {
        this.recomputeMaxAvailableLevels();
        this.loadBuildFromUrl();
        this.updateUrl();
        this.updateAwakenings();
    },
    beforeDestroy() {
        const { b, ...query} = this.$route.query;
        this.$router.replace({ query });

    },
    methods: {
        ...mapActions({
            setAvailableLevel: Actions.SetAvailableLevel,
            clearAwakeningMod: Actions.ClearAwakeningMod,
            setAwakeningMod: Actions.SetAwakeningMod,
            addAwakeningMod: Actions.AddAwakeningMod,
            deleteAwakeningMod: Actions.DeleteAwakeningMod,
        }),
        ...mapMutations({
            setSkillBuild: Mutations.SetSkillBuildLevel,
        }),
        updateUrl() {
            const build = this.skillBuild;
            // apply delta coding with positives only
            // -5 -4 -3 -2 -1  0 +1 +2 +3 +4 +5
            // 11  9  7  5  3  0  2  4  6  8 10

            const deltaCode = build;
            // let last = 0;
            // for (const v of build) {
            //     const delta = v - last;
            //     const encodedDelta = delta >= 0 ? delta * 2 : delta * -2 + 1;
            //     deltaCode.push(encodedDelta);
            //     last = v;
            // }

            // Now do RLE
            const tokens = [];
            let runValue = -1;
            let runLength = 0;
            for (const v of deltaCode) {
                if (v !== runValue) {
                    if (runValue !== -1) {
                        // Write it out
                        if (runLength < 4) {
                            for (let i = 0; i < runLength; ++i) {
                                tokens.push(runValue === 0 ? '' : alphabet[runValue]);
                            }
                        } else {
                            tokens.push(runValue === 0 ? '-' : `-${alphabet[runValue]}`);
                            tokens.push(runLength);
                        }
                    }

                    runValue = v;
                    runLength = 1;
                } else {
                    ++runLength;
                }
            }

            if (runValue !== -1 && runLength > 0) {
                if (runLength < 4) {
                    for (let i = 0; i < runLength; ++i) {
                        tokens.push(runValue === 0 ? '' : alphabet[runValue]);
                    }
                } else {
                    tokens.push(runValue === 0 ? '-' : `-${alphabet[runValue]}`);
                    tokens.push(runLength);
                }
            }

            const encoded = tokens.join(',');

            const query = { ...this.$route.query, b: encoded };
            this.$router.replace({ query });
        },
        loadBuildFromUrl() {
            const b = this.$route.query.b;
            if (!b) {
                return;
            }

            const newBuild = new Array(BUILD_LENGTH).fill(0);

            const tokens = b.split(',');
            let isRun = false;
            let runValue = -1;
            let index = 0;

            for (let token of tokens) {
                if (isRun) {
                    const length = Number(token);
                    if (isNaN(length)) {
                        return;
                    }

                    for (let i = 0; i < length; ++i) {
                        newBuild[index] = runValue;
                        ++index;
                    }

                    isRun = false;
                } else {
                    if (token === '') {
                        newBuild[index] = 0;
                    } else if (token === '-') {
                        isRun = true;
                        runValue = 0;
                        continue;
                    } else {
                        if (token.startsWith('-')) {
                            isRun = true;
                            token = token.substring(1);
                            const value = alphabet.indexOf(token);
                            if (value === -1) {
                                return;
                            }
                            
                            runValue = value;
                            continue;
                        }
                        
                        const value = alphabet.indexOf(token);
                        if (value === -1) {
                            return;
                        }

                        newBuild[index] = value;
                    }
                    ++index;
                }
            }

            this.setSkillBuild(newBuild);
        },
        computeTreeUsage(tree: ISkillTreeEntry[], offset: number) {
            let sum = 0;

            for (const entry of tree) {
                const skill = this.skills.get(entry.skillId);
                if (skill) {
                    const index = entry.index + offset + (entry.isAwakened ? 24 : 0);
                    const level = this.skillBuild[index];
                    const maxRegularLevel = skill.skill._MaxLevel - skill.skill._SPMaxLevel;
                    const add = skill.levels.filter((v) => v._SkillLevel <= level && v._ApplyType === 0 && v._SkillLevel <= maxRegularLevel).reduce((pv, v) => pv + v._NeedSkillPoint, 0);
                    sum += add;
                }
            }

            return sum;
        },
        indexToTree(idx: number): 'first'|'second'|'third' {
            const mod = Math.floor(idx / 24);
            switch (mod) {
                case 0: return 'first';
                case 1: return 'second';
                case 2: return 'third';
                default: return 'third';
            }
        },
        canSkillBeLearned(skillSlot: ISkillTreeEntry, sp: ISp, depth: number = 0, ignoreGlobal: boolean = false): boolean {
            if (depth > 5) {
                throw new Error(`canSkillBeLearned recursed too deep. ${skillSlot.skillId}`);
            }
            
            // Do we have the prerequisite SP landmarks reached
            if (skillSlot.spRequired.first > sp.first) {
                return false;
            }
            if (skillSlot.spRequired.second > sp.second) {
                return false;
            }
            if (skillSlot.spRequired.third > sp.third) {
                return false;
            }

            // Do we have the prerequisite skills
            if (skillSlot.parentSkills.length) {
                let failed = false;
                for (const parentSkill of skillSlot.parentSkills) {
                    const slot = this.skillTree.all.find((v) => v.skillId === parentSkill.id);
                    if (slot) {
                        const parentLevel = this.skillBuild[slot.index + (slot.tab - 1) * 24 + (slot.isAwakened ? 24 : 0)];
                        if (parentLevel < parentSkill.level) {
                            failed = true;
                            // console.log(`Failing ${skillSlot.skillId}, need ${parentSkill.id} level ${parentSkill.level}, have ${parentLevel}`);
                            break;
                        }
                    }
                }
                
                if (failed && !ignoreGlobal) {
                    // Check globalskillgroup - if at least one skill in a skill group can be learned, they all can
                    const skill = this.skills.get(skillSlot.skillId);
                    if (skill && skill.skill._GlobalSkillGroup > 0) {
                        const groupSkillIds = this.skillTree.all
                            .map((v) => this.skills.get(v.skillId))
                            .filter((v): v is ISkillFullData => !!v && v.skill._GlobalSkillGroup === skill.skill._GlobalSkillGroup && v.skill.id !== skill.skill.id)
                            .map((v) => v.skill.id);

                        for (const id of groupSkillIds) {
                            const treeEntry = this.skillTree.all.find((v) => v.skillId === id);
                            if (treeEntry) {
                                try {
                                    if (this.canSkillBeLearned(treeEntry, sp, depth + 1, true)) {
                                        return true;
                                    }
                                } catch (e) {
                                    console.error(`when processing ${skillSlot.skillId}`);
                                    throw e;
                                }
                            }
                        }
                    }
                    
                    return false;
                }
            }
            
            return true;
        },
        recomputeMaxAvailableLevels() {
            // Compute total used SP for each tree
            const sp: ISp = {
                first: this.computeTreeUsage(this.skillTree.first, 0),
                second: this.computeTreeUsage(this.skillTree.second, 24),
                third: this.computeTreeUsage(this.skillTree.third, 48),
                total: 0,
            };

            sp.total = sp.first + sp.second + sp.third;

            const remainingSp: ISp = {
                first: this.maxSp.first - sp.first,
                second: this.maxSp.second - sp.second,
                third: this.maxSp.third - sp.third,
                total: this.maxSp.total - sp.total,
            };

            const newMaxAvailable = new Array(BUILD_LENGTH).fill(0);
            
            for (let i = 0; i < BUILD_LENGTH; ++i) {
                const treeNum = Math.floor(i / 24);
                const idx = i % 24;
                const skillSlot = this.skillTree.all.find((v) => v.index == idx && v.tab === treeNum + 1);
                if (skillSlot) {
                    try {
                        if (!this.canSkillBeLearned(skillSlot, sp)) {
                            continue;
                        }
                    } catch (e) {
                        console.error(e);
                        console.error(`when processing ${skillSlot.skillId}`);
                        
                        continue;
                    }

                    const skill = this.skills.get(skillSlot.skillId);
                    if (skill) {
                        // Did we already learn any _DuplicatedSkillType
                        if (skill.skill._DuplicatedSkillType > 0) {
                            const dupeIds = this.skillTree.all
                                .map((v) => this.skills.get(v.skillId))
                                .filter((v): v is ISkillFullData => !!v && v.skill._DuplicatedSkillType === skill.skill._DuplicatedSkillType && v.skill.id !== skill.skill.id)
                                .map((v) => v.skill.id);
                            const treeEntries = this.skillTree.all.filter((v) => dupeIds.findIndex((t) => t === v.skillId) !== -1);
                            let failed = false;
                            for (const entry of treeEntries) {
                                const parentLevel = this.skillBuild[entry.index + (entry.tab - 1) * 24 + (entry.isAwakened ? 24 : 0)];
                                if (parentLevel > 0) {
                                    failed = true;
                                    break;
                                }
                            }
                            
                            if (failed) {
                                continue;
                            }
                        }
                        
                        // what's the max level we can get to before we run out of SP
                        let accum = 0;
                        const currLevel = this.skillBuild[i];
                        const remainingSpAmt = Math.min(remainingSp.total, remainingSp[this.indexToTree(i)]);
                        if (remainingSpAmt < 0) {
                            continue;
                        }

                        const maxLevel = skill.skill._MaxLevel - skill.skill._SPMaxLevel;
                        let okLevel = maxLevel;
                        for (let j = currLevel + 1; j <= maxLevel; ++j) {
                            const levelData = skill.levels.find((v) => v._SkillLevel === j && v._ApplyType === 0);
                            if (levelData) {
                                // required level
                                if (levelData._LevelLimit > this.skillSimState.levelCap) {
                                    okLevel = j - 1;
                                    break;
                                }

                                accum += levelData._NeedSkillPoint;
                                if (accum > remainingSpAmt) {
                                    okLevel = j - 1;
                                    break;
                                }
                            }
                        }

                        newMaxAvailable[i] = okLevel;
                    }
                }
            }

            this.setAvailableLevel(newMaxAvailable);
        },
        async updateAwakenings() {
            for (const slot of this.skillTree.all) {
                if (!slot.awakenedSkillId) {
                    continue;
                }
                
                const awakeningCondSkillTree = this.skillTree.all.find((v) => v.skillId === slot.awakenConditionSkillId);
                if (!awakeningCondSkillTree) {
                    continue;
                }
                
                const level = this.skillBuild[awakeningCondSkillTree.index + (awakeningCondSkillTree.tab - 1) * 24];
                
                if (level > 0) {
                    this.addAwakeningMod([slot.skillId, slot.awakenedSkillId]);
                } else {
                    this.deleteAwakeningMod(slot.skillId);
                }
            }
        }
    },
});
