







import Vue from 'vue';
import Toast from "@/components/util/Toast.vue";
import SkillSearch from "@/components/skill/SkillSearch.vue";

export default Vue.extend({
    components: {
        SkillSearch,
    },
    computed: {
        initialQuery(): string {
            return this.$route.query.q || '';
        }
    },
    methods: {
        onSearchQuery(query: string) {
            const location = {
                ...this.$route,
                query: {
                    ...this.$route.query,
                    q: query,
                },
            };

            this.$router.push(location);
        }
    }
});
