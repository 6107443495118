import Vue from 'vue';
export default Vue.extend({
    functional: true,
    props: {
        value: String as () => string,
    },
    render(h, ctx) {
        return h('span', ctx.props.value);
    },
});
