























import Vue, { VNode } from 'vue';
import ISkillEffect from '@/models/skills/ISkillEffect';
import ISkillEffectValue from '@/models/skills/ISkillEffectValue';
import { Blows } from "@/models/skills/IStateBlow";
import { filters } from '@/filters/Filters';
import ISkillLevel from '../../models/skills/ISkillLevel';
import BasicStringComponent from '@/util/BasicStringComponent';
import VNodeComponent from '@/util/VNodeComponent';

interface IData {
    description: string|VNode;
    descComponent: any;
}

export default Vue.extend({
    props: {
        "effect": {
            type: Object as () => ISkillEffect,
        },
        "value": {
            type: Object as () => ISkillEffectValue,
        },
        "forceShow": {
            type: Boolean as () => boolean,
        },
        "activeRankData": {
            type: Object as () => ISkillLevel,
        },
    },
    data(): IData {
        return {
            description: "Loading...",
            descComponent: BasicStringComponent,
        };
    },
    watch: {
        effect() {
            this.getDescription().then((v) => this.description = v);
        },
        value() {
            this.getDescription().then((v) => this.description = v);
        },
    },
    computed: {
        effectName(): string {
            let effectClass = this.effect.effectClass;
            let suffix = "";
            // if (effectClass === 0) {
            //     if (this.effect.index === 1 && this.activeRankData.pdmgBoardDamage != 0) {
            //         effectClass = 2;
            //         suffix = " (Slot 0)";
            //     } else if (this.effect.index === 2 && this.activeRankData.mdmgBoardDamage != 0) {
            //         effectClass = 29;
            //         suffix = " (Slot 1)";
            //     }
            // }

            const blows = Blows[effectClass];
            if (blows) {
                return blows.name + suffix;
            }

            return "";
        },
        display(): boolean {
            // Slot 0 and slot 1 are used for PDMG and MDMG if effectClass is zero
            // if (this.effect.effectClass === 0) {
            //     if (this.effect.index <= 2) {
            //         return !!this.value.value && this.value.value !== '0';
            //     }
            // }

            return this.forceShow || !(this.effect.effectClass == 0 && (!!this.value.value || this.value.value == ''));
        },
    },
    mounted() {
        this.getDescription().then((v) => {
            this.description = v;
            this.descComponent = typeof v === 'string' ? BasicStringComponent : VNodeComponent;
        });
    },
    methods: {
        async getDescription(): Promise<string|VNode> {
            let effectClass = this.effect.effectClass;
            // if (effectClass === 0) {
            //     if (this.effect.index === 1 && this.activeRankData.pdmgBoardDamage != 0) {
            //         effectClass = 2;
            //     } else if (this.effect.index === 2 && this.activeRankData.mdmgBoardDamage != 0) {
            //         effectClass = 29;
            //     }
            // }

            const blow = Blows[effectClass];
            if (blow) {
                if (blow.describeTsx) {
                    const desc = await blow.describeTsx(this.$createElement, this.effect, this.value);
                    if (desc) {
                        return desc;
                    }
                    
                    return '';
                } else if (blow.describe) {
                    let desc = blow.describe(this.effect, this.value);
                    if (typeof desc === "string") {
                        return desc;
                    } else if (desc == null) {
                        return "";
                    } else {
                        desc = await desc;

                        if (desc == null) {
                            return "";
                        }

                        let ret = desc.text;
                        if (desc.appendDuration && this.value && this.value.duration) {
                            ret += ` for ${filters.milliseconds(this.value.duration)}s`;
                        }

                        return ret;
                    }
                } else {
                    let ret = blow.name;
                    if (this.value) {
                        ret += `: ${this.value.value} for ${filters.milliseconds(this.value.duration)}s`;
                    }

                    return ret;
                }
            } else {
                let ret = `Effect ${this.effect.effectClass}`;
                if (this.value) {
                    ret += `: ${this.value.value} for ${filters.milliseconds(this.value.duration)}s`;
                }

                return ret;
            }
        },
    }
});
