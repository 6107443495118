













































































import Vue from 'vue';
import SpriteIcon from "@/components/util/SpriteIcon.vue";
import SkillStubLink from "@/components/skill/SkillStubLink.vue";
import ISkill from '../../models/skills/ISkill';
import ISkillLevel from '../../models/skills/ISkillLevel';
import { WeaponType, WeaponTypeName } from '../../models/items/ItemEnums';
import { SkillDurationType, SkillTargetType, SkillType } from '../../models/skills/SkillEnums';
import { ElementalType } from '@/models/common/ElementalType';
export default Vue.extend({
    components: {
        SkillStubLink,
        SpriteIcon,
    },
    props: {
        skillData: {
            type: Object as () => ISkill,
        },
        skillLevelData: {
            type: Array as () => ISkillLevel[],
        },
    },
    computed: {
        realMaxSkillLevel(): number {
            if (this.skillLevelData) {
                let r = Math.max(...this.skillLevelData.map((l) => l.level));
                if (!isFinite(r)) {
                    return NaN;
                }

                return r;
            }

            return NaN;
        },
        realMinSkillLevel(): number {
            if (this.skillLevelData) {
                return Math.min(...this.skillLevelData.map((l) => l.level));
            }

            return 1;
        },
        SkillType(): typeof SkillType {
            return SkillType;
        },
        weapons(): string {
            if (this.skillData) {
                return this.skillData.requiredWeapons.map((v) => {
                    return `${v}`;
                }).join(", ");
            }

            return "";
        },
        weaponsNice(): string {
            if (this.skillData) {
                return this.skillData.requiredWeapons.map((v) => {
                    const n = WeaponTypeName[v as WeaponType];
                    if (n) {
                        return n;
                    }

                    return `Weap ${v}`;
                }).join(', ');
            }

            return '';
            
        },
        skillType(): string {
            if (this.skillData) {
                switch (this.skillData.skillType) {
                    case SkillType.ACTIVE:
                        return 'Active skill';
                    case SkillType.PASSIVE:
                        return 'Passive skill';
                    case SkillType.AUTOMATIC_PASSIVE:
                        return 'Auto-triggered passive';
                    case SkillType.EX_PASSIVE:
                        return 'Skill enhancement';
                    case SkillType.AUTOMATIC_ACTIVATION:
                        return 'Automatically used';
                }
            }
            
            return 'Unknown';
        },
        applyType(): string {
            if (this.skillData) {
                switch (this.skillData.durationType) {
                    case SkillDurationType.INSTANT:
                        return 'Regular';
                    case SkillDurationType.BUFF:
                        return 'Buff';
                    case SkillDurationType.DEBUFF:
                        return 'Debuff';
                    case SkillDurationType.TIMED_TOGGLE:
                        return 'Timed toggle';
                    case SkillDurationType.ACTIVE_TOGGLE:
                        return 'Active toggle';
                    case SkillDurationType.AURA:
                        return 'Aura';
                    case SkillDurationType.ACTIVE_TOGGLE_PET:
                        return 'Pet skill on/off';
                    case SkillDurationType.SUMMON_DESUMMON_PET:
                        return 'Toggle pet';
                    case SkillDurationType.CHANGE_STANCE:
                        return 'Stance change';
                    case SkillDurationType.UNIFIED_SKILL:
                        return 'Unified skill';
                }
            }
            
            return 'Unknown';
        },
        primaryTarget(): string {
            if (this.skillData) {
                switch (this.skillData.targetType) {
                    case SkillTargetType.SELF:
                        return 'Caster';
                    case SkillTargetType.ENEMY:
                        return 'Enemy';
                    case SkillTargetType.ALLIED:
                        return 'Friendly actors';
                    case SkillTargetType.PARTY_MEMBERS:
                        return 'Party players';
                    case SkillTargetType.ALL:
                        return 'All';
                }
            }
            
            return 'Unknown';
        },
        elemental(): [string, string, string] {
            if (this.skillData) {
                switch (this.skillData.element) {
                    case ElementalType.FIRE:
                        return ['Fire', 'fire', 'fa-fire'];
                    case ElementalType.WATER:
                        return ['Water', 'ice', 'fa-snowflake-o'];
                    case ElementalType.LIGHT:
                        return ['Light', 'light', 'fa-bolt'];
                    case ElementalType.DARK:
                        return ['Dark', 'dark', 'fa-adjust'];
                }
            }
            
            return ['None', 'nonelemental', 'fa-no-entry'];
        }
    }
})
