


















import Vue from 'vue'
export default Vue.extend({
    props: {
        "errorTitle": {
            type: String as () => string,
        },
        "errorContent": {
            type: String as () => string,
        },
        "iconClass": {
            type: String as () => string,
        },
        "secondaryInfo": {
            type: String as () => string,
        },
        "canRetry": {
            type: Boolean as () => boolean,
        },
    },
    methods: {
        retry() {
            this.$emit("retry");
        }
    }
});
