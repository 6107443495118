






import SkillSim from '@/components/skill/skillsim/SkillSim.vue';
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import createSkillSimStore, { Actions } from './SkillSimStore';


const { mapState, mapActions } = createNamespacedHelpers('skillsim');


export default Vue.extend({
    components: { SkillSim },
    beforeCreate() {
        if (!this.$store.hasModule('skillsim')) {
            this.$store.registerModule('skillsim', createSkillSimStore());
        }  
    },
    mounted() {
        this.init(this.$route.query);
    },
    beforeDestroy() {
        this.$store.unregisterModule('skillsim');
    },
    methods: {
        ...mapActions([
            Actions.Init,
        ]),
    }
})
