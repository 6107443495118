










































import Loader from '@/components/util/Loader.vue';
import SkillSimClassPicker from './SkillSimClassPicker.vue';
import { Actions, Getters, SimState } from '@/views/SkillSimStore';
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import SkillSimSkillTree from './SkillSimSkillTree.vue';
import SkillSimCompute from './SkillSimCompute';

import SiteAuthHeader from "@/components/auth/SiteAuthHeader.vue";
import RegionHeader from "@/components/region/RegionHeader.vue";

const { mapState, mapActions, mapGetters } = createNamespacedHelpers('skillsim');

export default Vue.extend({
    components: { 
        Loader,
        SiteAuthHeader,
        RegionHeader,
        SkillSimClassPicker,
        SkillSimSkillTree,
        SkillSimCompute,
    },
    props: {
    },
    mounted() {

    },
    computed: {
        ...mapGetters({
            isLoading: Getters.IsLoading,
            simState: Getters.SimState,
        }),
        ...mapState({
            error: 'error',
            embedded: 'embedded',
        }),
        loadingText(): string {
            if (this.simState === SimState.LOADING_JOBS) {
                return 'Loading classes';
            } else if (this.simState === SimState.LOADING_SKILLS) {
                return 'Loading skill tree';
            }
            
            return '';
        },
        SimStateEnum(): typeof SimState {
            return SimState;
        },
        useInstanceText(): string {
            return process.env.VUE_APP_USE_STATIC_SERVER || '';
        },
    },
    methods: {
        ...mapActions([
            Actions.SetJob,
        ]),
    },
})
