
























import Loader from '@/components/util/Loader.vue';
import SpriteIcon from "@/components/util/SpriteIcon.vue";
import IJob from '@/models/jobs/IJob';
import { Actions, Getters, ISkillFullData, ISkillSimState, ISkillTree, ISp, SimState } from '@/views/SkillSimStore';
import Vue from 'vue';
import SkillSimSkillSubTree from './SkillSimSkillSubTree.vue';
import { createNamespacedHelpers } from 'vuex';

// @ts-ignore
import domtoimage from 'dom-to-image';
import ISkillTreeEntry from '@/models/skills/ISkillTreeEntry';

const { mapActions } = createNamespacedHelpers('skillsim');

export default Vue.extend({
    components: {
        Loader,
        SpriteIcon,
        SkillSimSkillSubTree,
    },
    props: {

    },
    mounted() {

    },
    computed: {
        skillSimState(): ISkillSimState {
            return (this.$store.state as any).skillsim;
        },
        embedded(): boolean {
            return this.skillSimState.embedded;
        },
        thirdJob(): IJob {
            return this.skillSimState.job!;
        },
        secondJob(): IJob {
            return this.thirdJob.parentJob!;
        },
        firstJob(): IJob {
            return this.secondJob.parentJob!;
        },
        skillTree(): ISkillTree {
            return this.skillSimState.skillTree;
        },
        maxSp(): ISp {
            return this.skillSimState.maxSp;
        },
        skills(): Map<number, ISkillFullData> {
            return this.skillSimState.skills;
        },
        skillBuild(): number[] {
            return this.skillSimState.skillBuild;
        },
        totalUsedSp(): number {
            return this.computeTreeUsage(this.skillTree.first, 0) +
                this.computeTreeUsage(this.skillTree.second, 24) +
                this.computeTreeUsage(this.skillTree.third, 48);
        },
        hasAwakening(): boolean {
            return this.skillSimState.skillTree.third.findIndex((v) => v.isAwakened) !== -1;
        },
    },
    methods: {
        ...mapActions({
            reset: Actions.Reset,
        }),
        back() {
            const { j, ...query } = this.$route.query;
            this.$router.push({ query });
            this.reset();
        },
        computeTreeUsage(tree: ISkillTreeEntry[], offset: number) {
            let sum = 0;

            for (const entry of tree) {
                const skill = this.skills.get(entry.skillId);
                if (skill) {
                    const index = entry.index + offset + (entry.isAwakened ? 24 : 0);
                    const level = this.skillBuild[index];
                    const maxRegularLevel = skill.skill._MaxLevel - skill.skill._SPMaxLevel;
                    const add = skill.levels.filter((v) => v._SkillLevel <= level && v._ApplyType === 0 && v._SkillLevel <= maxRegularLevel).reduce((pv, v) => pv + v._NeedSkillPoint, 0);
                    sum += add;
                }
            }

            return sum;
        },
        async screenshot() {
            const div = this.$refs.trees;
            if (div) {
                domtoimage.toBlob(div).then((blob: Blob) => {
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = 'build.png';
                    link.click();
                    URL.revokeObjectURL(link.href);
                });
                
            }
        }
    },
})
