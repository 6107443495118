




















import Loader from '@/components/util/Loader.vue';
import SpriteIcon from "@/components/util/SpriteIcon.vue";
import IJob from '@/models/jobs/IJob';
import { Actions, Getters, SimState } from '@/views/SkillSimStore';
import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';

const { mapState, mapActions, mapGetters } = createNamespacedHelpers('skillsim');

interface IBaseClassSet {
    baseJob: IJob;
    jobs: IJob[];
}

function getBaseClass(job: IJob): IJob {
    if (!job.parentJob) {
        return job;
    }

    return getBaseClass(job.parentJob);
}

export default Vue.extend({
    components: {
        Loader,
        SpriteIcon,
    },
    props: {

    },
    mounted() {

    },
    computed: {
        ...mapGetters({
            simState: Getters.SimState,
        }),
        ...mapState({
            jobs: 'jobs',
        }),
        baseClassSets(): IBaseClassSet[] {
            const baseJobs = new Map<number, IBaseClassSet>();
            for (const j of this.jobs as IJob[]) {
                const parent = getBaseClass(j);
                if (baseJobs.has(parent.id)) {
                    const bcs = baseJobs.get(parent.id)!;
                    bcs.jobs.push(j);
                } else {
                    baseJobs.set(parent.id, {
                        baseJob: parent,
                        jobs: [j],
                    });
                }
            }

            return [...baseJobs.values()];
        }
    },
    methods: {
        ...mapActions({
            setJob: Actions.SetJob,
        }),
        async selectJob(job: IJob) {
            await this.setJob(job);
            this.$router.push({ query: {...this.$route.query, j: String(job.id)}});
        }
    },
})
